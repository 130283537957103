import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
// import Icon from "./Icons";

const HeaderLink = ({ label, children }) => (
	<div className="text-primary lg:text-lg md:text-base text-sm mx-4 md:my-1 text-right">
		<span className="text-primary-dark font-bold mr-1">{label}</span>
		{children}
	</div>
);

HeaderLink.propTypes = {
	label: PropTypes.string.isRequired,
	children: PropTypes.string.isRequired,
};
// const HeaderLink = ({ text, icon, label }) => (
// 	<div className="flex flex-row text-primary items-center">
// 		{icon ? <Icon name={icon} /> : <span className="">{label}</span>}
// 		<span className="ml-3">{text}</span>
// 	</div>
// );

// HeaderLink.propTypes = {
// 	text: PropTypes.string.isRequired,
// 	icon: PropTypes.string,
// 	label: PropTypes.string,
// };

// HeaderLink.defaultProps = {
// 	icon: null,
// 	label: null,
// };

function Header({ logo }) {
	return (
		<nav className="flex content-around justify-between px-16 py-4 flex-col md:flex-row items-center">
			<Link className="flex-grow w-full text-center md:text-left" to="/">
				{logo}
			</Link>
			<div className="flex-grow flex w-full flex-row md:flex-col flex-wrap mt-2 md:mt-auto">
				<HeaderLink label="Phone:">(08) 9389 1733</HeaderLink>
				<HeaderLink label="Fax:">(08) 9389 1755</HeaderLink>
				<HeaderLink label="Email:">info@advancedgiwa.com.au</HeaderLink>
				<HeaderLink label="Healthlink:">advancgi</HeaderLink>
				{/* <HeaderLink icon="phone" text="(08) 9389 1733" />
				<HeaderLink icon="mail" text="info@advancedgiwa.com.au" />
				<HeaderLink label="Healthlink:" text="Healthlink:advancgi" /> */}
			</div>
		</nav>
	);
}
// function Header({ logo }) {
// 	return (
// 		<nav className="flex flex-wrap items-center justify-between max-w-xl mx-auto p-4 md:p-8">
// 			<Link to="/">{logo}</Link>
// 		</nav>
// 	);
// }

Header.propTypes = {
	logo: PropTypes.node.isRequired,
};

export default Header;
