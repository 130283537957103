import { Link } from "gatsby";
import React, { useState } from "react";
import { listOfTestimonials } from "../data/listOfTestimonials";

const activeStyle = {
	color: "#196C98",
};

const Navbar = () => {
	const [isExpanded, toggleExpansion] = useState(false);
	const linkClasses =
		"block sm:inline-block mt-4 sm:mt-0 mr-6 no-underline text-primary text-center hover:text-primary-dark";
	const clickLink = () => toggleExpansion(false);

	return (
		<nav className="sticky top-0 z-50 bg-white border-b border-primary">
			<div className="flex flex-wrap items-center justify-center max-w-3xl mx-auto p-4 md:p-8">
				<button
					className="block sm:hidden border border-primary flex items-center px-3 py-2 rounded text-primary"
					onClick={() => toggleExpansion(!isExpanded)}
					type="button"
				>
					<svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
						<title>Menu</title>
						<path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
					</svg>
					<span className="ml-4">Menu</span>
				</button>
				<div className={`${isExpanded ? `block` : `hidden`} text-lg sm:block sm:flex sm:items-center w-full sm:w-auto`}>
					<Link to="/" className={linkClasses} onClick={clickLink} activeStyle={activeStyle}>
						Home
					</Link>

					<Link to="/our-team" className={linkClasses} onClick={clickLink} activeStyle={activeStyle}>
						Our Team
					</Link>

					<Link to="/services" className={linkClasses} onClick={clickLink} activeStyle={activeStyle}>
						Services
					</Link>
					{/* {listOfTestimonials.length > 0 ? (
						<Link to="/testimonials" className={linkClasses} onClick={clickLink} activeStyle={activeStyle}>
							Testimonials
						</Link>
					) : null} */}
					<Link to="/patient-information" className={linkClasses} onClick={clickLink} activeStyle={activeStyle}>
						Patient Information
					</Link>
					<Link to="/articles" className={linkClasses} onClick={clickLink} activeStyle={activeStyle}>
						Articles
					</Link>
					<Link to="/Newsletters" className={linkClasses} onClick={clickLink} activeStyle={activeStyle}>
						Newsletters
					</Link>
					<Link to="/contact" className={linkClasses} onClick={clickLink} activeStyle={activeStyle}>
						Contact
					</Link>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
