import { graphql, Link, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import React from "react";
import Header from "../components/header";
import Icon from "../components/Icons";
import Navbar from "../components/navbar";

const NavLink = ({ text, to, blank, icon, active }) => (
	<a
		href={to}
		className="2 flex-1 flex flex-col items-center py-2 no-underline text-primary hover:bg-grey-light hover:text-primary-dark hover:font-semibold"
		target={blank ? "_blank" : null}
		rel="noopener noreferrer"
	>
		<Icon name={icon} active={active} />
		<span className={`text-xs ${active ? "font-semibold" : ""}`} style={{ marginTop: "5px" }}>
			{text}
		</span>
	</a>
);

NavLink.propTypes = {
	text: PropTypes.string.isRequired,
	to: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	blank: PropTypes.bool,
	active: PropTypes.bool,
};

NavLink.defaultProps = {
	blank: false,
	active: false,
};

function Layout({ children }) {
	const year = new Date().getFullYear();
	const address = `Hollywood Specialist Centre Suite 10/11 95 Monash Ave. Nedlands WA 6009`.replace(/\s/g, "+");
	return (
		<StaticQuery
			query={graphql`
				query {
					site: site {
						siteMetadata {
							title
						}
					}
					logo: file(relativePath: { eq: "advanced-gi-logo.png" }) {
						childImageSharp {
							fixed(height: 60) {
								...GatsbyImageSharpFixed_withWebp
							}
						}
					}
				}
			`}
			render={({ logo, site }) => (
				<>
					<div style={{ overflowX: "hidden" }} className="flex flex-col font-sans min-h-screen text-grey-darkest">
						{/* <Link to="/articles/covid-19-planning" className="no-underline">
							<div className="w-full text-white bg-red-dark hover:font-semibold top-0 z-50 bg-white border-b border-primary p-4 text-center">
								IMPORTANT: Information about coronavirus (COVID-19), please click here and read prior to your
								appointment.							</div>
						</Link> */}
						<Header siteTitle={site.siteMetadata.title} logo={<Img fixed={logo.childImageSharp.fixed} />} />
						<Navbar />
						<div className="flex flex-col flex-1 max-w-main w-full mx-auto mb-16 px-12 pb-8 tracking-wide leading-normal">
							{children}
						</div>
						<footer className="bg-primary hidden md:block text-white">
							<div className="flex justify-center max-w-xl mx-auto p-4 md:p-8 text-sm">
								<p className="text-white">Copyright © {year} Advanced GI WA Pty Ltd. All rights reserved.</p>
								{/* <p className="no-underline text-white">Oak</p> */}
							</div>
						</footer>
						<footer className="md:hidden fixed bottom-0 bg-grey-lightest w-screen ">
							<nav className="flex border-t border-primary">
								<NavLink to="tel:0893891733" text="08 9389 1733" icon="phone" />
								<NavLink to="mailto:info@advancedgiwa.com.au" text="Email" icon="mail" />
								<NavLink to={`http://maps.google.com/?q=${address}`} text="Directions" blank icon="map" active />
							</nav>
						</footer>
					</div>
				</>
			)
			}
		/>
	);
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
