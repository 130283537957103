export const listOfTestimonials = [
	{
		text: "If all medical procedures and medical contacts were as easy as this experience the world would be a better place. Can I also say that all the staff from the doctors to the volunteers were as kind and courteous as i have ever come across, Thanks everybody",
		// 	name: "Richard Feynman",
	},
	{
		text: "Being a business owner myself I recognise and appreciate the high professional standards your organisation delivers.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Superb service. By far the best in WA, then it should be the best in Australia and perhaps one of the best in the world too!!  Keep it up!",
		// 	name: "Richard Feynman",
	},
	{
		text: "I felt safe and secure and everyone was friendly and engaging",
		// 	name: "Richard Feynman",
	},
	{
		text: "Great service, Spiro was fantastic",
		// 	name: "Richard Feynman",
	},
	{
		text: "My doctor and receptionist were wonderful. Between my husband and my medical visits this would have to be one of our better experiences. Thank you",
		// 	name: "Richard Feynman",
	},
	{
		text: "Thank you so much for your efficiency throughout the whole process. Staff are absolutely wonderful.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Excellent service, painless and trouble-free experience with the procedure",
		// 	name: "Richard Feynman",
	},
	{
		text: "Dr Rao was outstanding! His explanations were clear and comprehensive. Importantly he “took the time” to ensure that the patient was fully informed, intelligently questioned about ongoing health issues as he outlined options and likely scenarios going forward. A most impressive, professional doctor who ensured that all aspects of future possible treatments and a repeat MRI were fully explained and understood. Very much appreciated his “doctor to patient” style and communication skills.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Receptionist very helpful indeed",
		// 	name: "Richard Feynman",
	},
	{
		text: "Very efficient with friendly caring staff made my procedure less stressful",
		// 	name: "Richard Feynman",
	},
	{
		text: "Very accomodating and helpful",
		// 	name: "Richard Feynman",
	},
	{
		text: "An excellent service, friendly staff, efficient process",
		// 	name: "Richard Feynman",
	},
	{
		text: "Very accomodating and helpful",
		// 	name: "Richard Feynman",
	},
	{
		text: "I find all the staff nurses and doctor very helpful and friendly very professional",
		// 	name: "Richard Feynman",
	},
	{
		text: "Very efficient with friendly caring staff made my procedure less stressful",
		// 	name: "Richard Feynman",
	},
	{
		text: "I find all the staff nurses and doctor very helpful and friendly very professional",
		// 	name: "Richard Feynman",
	},
	{
		text: "Very friendly staff. Well informed by lovely Dr.",
		// 	name: "Richard Feynman",
	},
	{
		text: "I will tell my friends about you and your very friendly and helpful service",
		// 	name: "Richard Feynman",
	},
	{
		text: "Very efficient with friendly caring staff made my procedure less stressful",
		// 	name: "Richard Feynman",
	},
	{
		text: "Everything went very smoothly. Staff very friendly , attentive and accommodating.",
		// 	name: "Richard Feynman",
	},
	{
		text: "The staff were just great and made the whole experience easy. A big thank you and a Merry Christmas to them all.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Everyone is extremely helpful and professional",
		// 	name: "Richard Feynman",
	},
	{
		text: "A very thoroughly professional experience.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Thank you immensely for your service and care - very much appreciated.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Overall fantastic service.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Dr Spiro Raftopoulos and Staff were Brilliant could not fault their professionalism and care.",
		// 	name: "Richard Feynman",
	},
	{
		text: "There was excellent follow up. Even though I couldn’t make it due to being in hospital the staff contacted me so I didn’t worry re cancelling at the last minute. They had kindly squeezed me in the day after I called.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Treated extremely well, as always. Thank you",
		// 	name: "Richard Feynman",
	},
	{
		text: "it’s hard to improve on excellence!",
		// 	name: "Richard Feynman",
	},
	{
		text: "Medical team, hospital staff, facility and new complex is all very efficient and extremely friendly. I found everyone in good spirit, helpful and enjoying their respective jobs. God bless Australia !!! My visit was faultless.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Thanks for great service and attention to my needs",
		// 	name: "Richard Feynman",
	},
	{
		text: "Everthing was explained throught the procedure very professional",
		// 	name: "Richard Feynman",
	},
	{
		text: "As good as the last time and the time before and before etc",
		// 	name: "Richard Feynman",
	},
	{
		text: "I have full confidence in Dr Rao and his planned approach to a complex medical issue.",
		// 	name: "Richard Feynman",
	},
	{
		text: "The staff and nurses were excellent Both the Doctor and anaesthetic came and explained the procedures",
		// 	name: "Richard Feynman",
	},
	{
		text: "Staff were very helpful with very pleasant dispositions towards my wife and I.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Dr Pham was helpful on our Telehealth call and I feel confident he will be helpful going forward in my care.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Everything was excellent thank you.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Dr Gordon took care of my IBD during pregnancy and ongoing. She has been the only specialist to pay full attention and treat me holistically throughout (including labour). I feel extremely safe in her care, and that’s a first for me.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Your staff are a credit to you!",
		// 	name: "Richard Feynman",
	},
	{
		text: "All the hospital staff were great. For the first time in my 88 years I've had a happy birthday in hospital.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Even the lead up to the procedure with the booking and information requested was brilliant ... happy to be a referral Thankyou",
		// 	name: "Richard Feynman",
	},
	{
		text: "The whole process has been seamless with friendly and professional staff from the admin staff to Dr Rao.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Thank you :)",
		// 	name: "Richard Feynman",
	},
	{
		text: "Thank you, everything was easy from start to finish",
		// 	name: "Richard Feynman",
	},
	{
		text: "Very grateful to be a patient of Dr Spiro",
		// 	name: "Richard Feynman",
	},
	{
		text: "An excellent service with exceptionally nice people looking after the patients",
		// 	name: "Richard Feynman",
	},
	{
		text: "What a fantastic well oiled professional entity!",
		// 	name: "Richard Feynman",
	},
	{
		text: "Very caring place",
		// 	name: "Richard Feynman",
	},
	{
		text: "Outstanding care and commitment",
		// 	name: "Richard Feynman",
	},
	{
		text: "Frank is a gentleman and a scholar. No only did he provide excellent service throughout the procedure, he also provided great council and peace of mind which I put above all else. I can't put into words how important this was for me. Plus he does it all with a great sence of humour...what an awesome human being. Thankyou very much!",
		// 	name: "Richard Feynman",
	},
	{
		text: "Yes I felt like I mattered to both the Doctor and Receptionist and this means a lot to me.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Helpful and friendly staff.",
		// 	name: "Richard Feynman",
	},
	{
		text: "I was very well cared and most impressed in relation to the whole process from end to end, including the notification and booking of the procedure; the experience with the staff at the front desk on the day of the procedure; the care provided by Jess & Helen before and after the procedure; Brendon Dunlop & Dr Dev's treatment; and Clare's care afterwards - thank you and well done to you all.",
		// 	name: "Richard Feynman",
	},
	{
		text: "I was pleased with all the staff very happy",
		// 	name: "Richard Feynman",
	},
	{
		text: "Communication was good with the Doctor and others and the staff at day procedure are excellent",
		// 	name: "Richard Feynman",
	},
	{
		text: "All went well and Spiro once again achieved great results with minimal discomfort post op. I would highly recommend him. Thank you.",
		// 	name: "Richard Feynman",
	},
	{
		text: "We have always found Spiro and his staff very competent, professional, thorough and friendly.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Everyone was very kind and understanding.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Cannot speak highly enough of your services. Always come away feeling very cared for.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Only rated as an 8 because it is such a personal decision - nothing for you to improve on but would always encourage others to make their own decisions.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Well done",
		// 	name: "Richard Feynman",
	},
	{
		text: "Very thorough and caring consultations",
		// 	name: "Richard Feynman",
	},
	{
		text: "Excellent Doctor and extremely pleasant and helpful Receptionist",
		// 	name: "Richard Feynman",
	},
	{
		text: "Every time I have been, the staff have been friendly and the Doctor has been on time.",
		// 	name: "Richard Feynman",
	},
	{
		text: "The staff and nurses and my Doc (Frank Brennan) were all very pleasant, thoughtful and attentive.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Thanks Spiro and the great team",
		// 	name: "Richard Feynman",
	},
	{
		text: "Everything was super smooth at the hospital, all staff were lovelY and looked after me very well",
		// 	name: "Richard Feynman",
	},
	{
		text: "The food was excellent Much improved from last time which was good.",
		// 	name: "Richard Feynman",
	},
	{
		text: "All staff and doctors were very understanding and made me feel very comfortable at all times.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Excellent care in a clean , professional hospital environment.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Love your team, just fabulous!",
		// 	name: "Richard Feynman",
	},
	{
		text: "Very friendly personalised service, thanks",
		// 	name: "Richard Feynman",
	},
	{
		text: "I am happy...",
		// 	name: "Richard Feynman",
	},
	{
		text: "As a patient I felt very much at ease and all my questions were answered.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Very satisfied with staff and Dr.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Thank you",
		// 	name: "Richard Feynman",
	},
	{
		text: "All very helpful and friendly.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Overall very happy with my experience",
		// 	name: "Richard Feynman",
	},
	{
		text: "Many thanks for your much appreciated, highly professional and fully supportive service, which provided a first rate patient experience.",
		// 	name: "Richard Feynman",
	},
	{
		text: "You guys are great and Spiro is an amazing doctor. Thanks",
		// 	name: "Richard Feynman",
	},
	{
		text: "All good",
		// 	name: "Richard Feynman",
	},
	{
		text: "Thank you for taking such good care of me.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Always friendly and professional",
		// 	name: "Richard Feynman",
	},
	{
		text: "Feeling taken care of at every step of the way by Dr Spiro Raftopoulos is very comforting. So a Big Thank you to Dr Spiro.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Thank you for taking such good care of me.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Keep up the good work",
		// 	name: "Richard Feynman",
	},
	{
		text: "A happy customer Dr Spiro is the best",
		// 	name: "Richard Feynman",
	},
	{
		text: "The whole team were great",
		// 	name: "Richard Feynman",
	},
	{
		text: "Everything was explained to me. A very positive experience",
		// 	name: "Richard Feynman",
	},
	{
		text: "Have had several procedures done by Advanced GI and always found them to be well organised and the staff have always been courteous and friendly. Thank you.",
		// 	name: "Richard Feynman",
	},
	{
		text: "I was looked after very well. Thankyou to the nurses etc involved",
		// 	name: "Richard Feynman",
	},
	{
		text: "Thank you for seeing me on Friday Sam.",
		// 	name: "Richard Feynman",
	},
	{
		text: "I was very happy with my visit. All staff are very efficient, friendly and helpful. I wouldn't hesitate to recommend Dr Frank Brennan. I wish he was my family doctor. His advice is practical and down to earth and he communicates extremely well.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Very friendly, competent staff. As a nurse from a Public Hospital surgical ward I was very impressed by the care and your facilities.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Thanks for looking after me",
		// 	name: "Richard Feynman",
	},
	{
		text: "All the staff and nurses made me feel very comfortable and relaxed and they did a great job",
		// 	name: "Richard Feynman",
	},
	{
		text: "Excellent communication and assistance received from all staff members, particularly Maureen (at front desk and via phone and email) and Dr Frank Brennan. ",
		// 	name: "Richard Feynman",
	},
	{
		text: "Staff helpful, friendly, courteous. Communication excellent. Process from initial booking to procedure completely flawless.",
		// 	name: "Richard Feynman",
	},
	{
		text: "The staff made you feel at ease from the time you arrived at Reception to leaving the ward at Peel Health Campus . All staff ... were professional at all times.",
		// 	name: "Richard Feynman",
	},
	{
		text: "I was riddled with anxiety in the lead up to the procedure but was extremely impressed by how your practice operated on the day.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Well done great service all round",
		// 	name: "Richard Feynman",
	},
	{
		text: "Well done great service all round",
		// 	name: "Richard Feynman",
	},
	{
		text: "very satifying",
		// 	name: "Richard Feynman",
	},
	{
		text: "Have built a rapport with Dr Spiro over the years have great respect & confidence in my specialist. Receptionist always friendly & efficient very happy with this service thank you.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Brilliant staff, love the heated blankets",
		// 	name: "Richard Feynman",
	},
	{
		text: "Love my Dr!",
		// 	name: "Richard Feynman",
	},
	{
		text: "Good friendly service from Consultant through to reception staff",
		// 	name: "Richard Feynman",
	},
	{
		text: "Such a professionally run company and wonderful friendly staff. A pleasure to deal with",
		// 	name: "Richard Feynman",
	},
	{
		text: "Very happy to be looked after by Dr Spiro Raftopoulos. He has complete empathy and commitment to the best possible outcomes for his patients. Thank you, Dr Spiro.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Being very remote it was fantastic to have a professional company that help me with the process.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Happy chappy.",
		// 	name: "Richard Feynman",
	},
	{
		text: "I was professionally and compassionately treated, my queries listened to and clearly answered. The hospital was spotless and comfortable, and admission was quick and staff competent and actually friendly!! Thank you to everyone for taking such wonderful care of me. ",
		// 	name: "Richard Feynman",
	},
	{
		text: "Like how we get a very comprehensive report before we go home, it’s comforting.",
		// 	name: "Richard Feynman",
	},
	{
		text: "I Can't add to what was a perfect experience. Dr Spiro absolutely brilliant on all basis. I was a bit unlucky that as the last patient for the day I missed out on the Crayfish Sandwhiches. ",
		// 	name: "Richard Feynman",
	},
	{
		text: "Excellent service Dr Spiro and all nursing staff very professional yet friendly.",
		// 	name: "Richard Feynman",
	},
	{
		text: "I have previously graded the excellent service but I thought I should make a couple of other comments. eg I think the survey should ask a couple of questions about the relationship eg professionalism of the doctor, the anesthetist and the nurses with the patient. In my case the professionalism and care exhibited by all three professions was second to none and if I could rate them higher than 10, I would. At all times I felt safe and secure in their care ....... and the lady at the front counter, she is all of brilliant, funny and competent. You have a great team at the Peel Campus. ",
		// 	name: "Richard Feynman",
	},
	{
		text: "Thank you to Dr Spiro & the team at Peel Health Campus Endoscopy Unit. Everyone was friendly, supportive & very caring to me & the other patients in the Unit. I really appreciated their efforts. Thank you ",
		// 	name: "Richard Feynman",
	},
	{
		text: "Thankyou,you made what could have been a difficult experience quite easy!",
		// 	name: "Richard Feynman",
	},
	{
		text: " Coming to advanced GI was a lovely experience. Seeing Maureen again after 10 years was quite special. Having a professional and kind person to greet you makes such a difference. Meeting Dr Duy was a pleasure. I appreciated his compassion, kindness and professional advice during our initial consult. I left feeling like I have the best care in Perth. Thank you",
		// 	name: "Richard Feynman",
	},
	{
		text: "Very friendly people",
		// 	name: "Richard Feynman",
	},
	{
		text: "As usual fantastic service making me feel secure and relaxed",
		// 	name: "Richard Feynman",
	},
	{
		text: "Your staff are excellent from the specialist center receptionist and golf cart driver taking me to the building after going to the wrong entrance through to all medical and clerical staff. I couldn't recommend more highly the pleasant disposition and kind care of your team at Hollywood.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Great work!",
		// 	name: "Richard Feynman",
	},
	{
		text: "Excellent service over all well looked after",
		// 	name: "Richard Feynman",
	},
	{
		text: "Thank you to all staff involved.",
		// 	name: "Richard Feynman",
	},
	{
		text: "I live in the country and had a phone consult. The Doctor was on time & very easy to communicate with. It made the whole process so easy to negotiate & manage without having to make the trip to Perth.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Spiro's bedside manner and explanations were excellent.",
		// 	name: "Richard Feynman",
	},
	{
		text: "I am extremely happy with my Doctor and reception staff.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Keep up the good case follow ups and informative customer service.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Dr Spiro was extremely professional, knowledgeable and caring. I have more confidence in Dr Spiro than any other doctor I have been seeing for this ailment in 20+ years.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Wonderful ,friendly staff,from office to all nursing staff.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Very much appreciate the assistance provided by Maureen via the phone and email. And appreciate Dr Spiro going above and beyond to ensure the best care of his patients and having direct and timely personal communication despite his busy schedule. 10/10 service.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Thank you so much for looking after me",
		// 	name: "Richard Feynman",
	},
	{
		text: " Keep up the good work",
		// 	name: "Richard Feynman",
	},
	{
		text: "very friendly staff in a relaxed environment",
		// 	name: "Richard Feynman",
	},
	{
		text: "I found the administration staff more than helpful an Dr Raftopoulis met all my needs and explained all my needs with my health.",
		// 	name: "Richard Feynman",
	},
	{
		text: "A big thank you to all the wonderful people at Advanced GI",
		// 	name: "Richard Feynman",
	},
	{
		text: "Both nursing staff and doctors were excellent",
		// 	name: "Richard Feynman",
	},
	{
		text: "Staff and service is excellent",
		// 	name: "Richard Feynman",
	},
	{
		text: "Excellent service over all well looked after",
		// 	name: "Richard Feynman",
	},
	{
		text: "A big thank you to all the wonderful people at Advanced GI",
		// 	name: "Richard Feynman",
	},
	{
		text: "Very pleasant people",
		// 	name: "Richard Feynman",
	},
	{
		text: "Excellent and professional service.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Excellent care & treatment, so refreshing to be treated so well",
		// 	name: "Richard Feynman",
	},
	{
		text: "Thank you to the Dr, anaesthetic and wonderful staff. They were all very caring.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Really good experience, all around. Thanks",
		// 	name: "Richard Feynman",
	},
	{
		text: "Dr Claire Gordon is very personable and easy to discuss things with",
		// 	name: "Richard Feynman",
	},
	{
		text: "everyone was fantastic............ill come again!!",
		// 	name: "Richard Feynman",
	},
	{
		text: "Spiro and the team were fantastic. Caz in the office super efficient",
		// 	name: "Richard Feynman",
	},
	{
		text: "Excellent caring service.",
		// 	name: "Richard Feynman",
	},
	{
		text: "All the staff were so helpful and concerned for my husband I wanted to hug everyone. Thanks them so much from me",
		// 	name: "Richard Feynman",
	},
	{
		text: "Secretary was very friendly and professional.",
		// 	name: "Richard Feynman",
	},
	{
		text: " I loved the relaxed yet professional service from admin toDr Brennan. Made me feel listened to and gave me great advice with a follow up booked on the day. Thank you.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Fantastic.  I couldn’t be happier with how everything was done.  Friendly, courteous and efficient.  Very happy customer.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Extremely grateful for the professional service I received especially by Dr Raftopoulos.					",
		// 	name: "Richard Feynman",
	},
	{
		text: "Keep it up … you make me feel better.",
		// 	name: "Richard Feynman",
	},
	{
		text: "VERY HAPPY WITH THE TREATMENT AND CONSULTATION",
		// 	name: "Richard Feynman",
	},
	{
		text: "All the staff, nurses and the doctors just so good in dealing with me. Nice lounge and good food.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Very friendly.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Lovely friendly admin staff very helpful and polite, makes such a difference when you are stressed about a medical condition and are search for answers, previous experience elsewhere was not good! ",
		// 	name: "Richard Feynman",
	},
	{
		text: "All of the staff were friendly and helpful. They all made me feel comfortable, welcome and well informed. I cannot prasie your staff more- they were awesome.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Thank you all so much. The Hollywood staff were lovely, too.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Everything was great thank you.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Very professional through out the entire process.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Friendly, efficient, trusted the medical professionals, beautiful facilities. Made the procedure a calmer experience than anticipated. ",
		// 	name: "Richard Feynman",
	},
	{
		text: "Dr Raftopoulos and the anaesthetist were great, as was Kaye (nurse who prep’d me), and theatre nurses.  Thank you. ",
		// 	name: "Richard Feynman",
	},
	{
		text: "All the staff make the process easy and as comfortable as possible. So grateful.",
		// 	name: "Richard Feynman",
	},
	{
		text: "The staff were lovely, nurses, orderlies, drs and admin staff. The food post procedure was of a high standard. ",
		// 	name: "Richard Feynman",
	},
	{
		text: "As always seamlessly done, all staff ands support from front desk to 'back end' perfect and professional. Thankyou.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Excellent service.  Lovely staff and everyone was most helpful and kind.",
		// 	name: "Richard Feynman",
	},
	{
		text: "A totally professional environment everyone caring and easy going.",
		// 	name: "Richard Feynman",
	},
	{
		text: "My doctor, Dev has gone above and beyond for me and always thinking out of the box. He has had a holistic approach to my problem and looks at my whole context and I really appreciate it. ",
		// 	name: "Richard Feynman",
	},
	{
		text: "Excellent - thank you.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Nursing staff were excellent. Call out specifically to Jaz and Jess.  Thought the whole process was very professional. Thank you. 🙏 ",
		// 	name: "Richard Feynman",
	},
	{
		text: "Everything was very efficient. Thanks. ",
		// 	name: "Richard Feynman",
	},
	{
		text: "Extremely friendly and efficient",
		// 	name: "Richard Feynman",
	},
	{
		text: "All staff from front desk nurses porter etc all very friendly and professional ",
		// 	name: "Richard Feynman",
	},
	{
		text: "The staff are amazing ",
		// 	name: "Richard Feynman",
	},
	{
		text: "I continue to keep this practice at very top",
		// 	name: "Richard Feynman",
	},
	{
		text: "Lovely nurse in the lounge after the procedure, couldn't do enough! Much appreciated and felt cared for.  Procedure was done with ease and anaesthetist very kind. Appreciated the follow up report. Doctor was very kind and clear with the information. Felt I was in good hands.",
		// 	name: "Richard Feynman",
	},
	{
		text: "100% satisfactory in pre, procedure and post care",
		// 	name: "Richard Feynman",
	},
	{
		text: "Fantastic",
		// 	name: "Richard Feynman",
	},
	{
		text: "Very friendly, efficient ,knowledgeable staff ",
		// 	name: "Richard Feynman",
	},
	{
		text: "All the staff were so helpful and most importantly friendly ",
		// 	name: "Richard Feynman",
	},
	{
		text: "So we’ll cared for while waiting between appointments.  Appointments made on the spot as we are country patients.  Best place.",
		// 	name: "Richard Feynman",
	},
	{
		text: "I was very impressed with the ease and satisfaction I felt and experienced  visiting this practice. The no waiting to see my specialist, just a few minutes, to the in depth explanation I received regards my health..   The staff were pleasant  and professional.",
		// 	name: "Richard Feynman",
	},
	{
		text: "So appreciative to have Dr Spiro taking care of me.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Staff excellent, as were Dr's.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Very satisfied.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Thank you for a very caring Doctor and staff.",
		// 	name: "Richard Feynman",
	},
	{
		text: "The fact that Advanced GI WA is a no gap service and charges only what HBF covers is greatly appreciated. And the same for the Anesthesiast used by Dr Brennan.  Dr Brennan is a quality professional and gentleman.  All the staff I had contact with from Advanced GI WA have been friendly and helpful.  Thanks",
		// 	name: "Richard Feynman",
	},
	{
		text: "Excellent service, friendly and professional staff, nice facilities. Would recommend",
		// 	name: "Richard Feynman",
	},
	{
		text: "Excellent throughout, from communication pre surgery to treatment on the day from all staff ; receptionist, nurse, anaesthetist and surgeon was excellent.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Overall very satisfied with service.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Very thorough and reassuring ",
		// 	name: "Richard Feynman",
	},
	{
		text: "Dr Spiro Raftopoulos was very caring and went beyond the normal with my care amazing doctor ",
		// 	name: "Richard Feynman",
	},
	{
		text: "Very helpful staff who were extremely professional in their approach to my appointment and my own issues making it to the centre on time. Doctor was excellent and very thorough in attempting to get to the bottom of my illness and forward plan.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Everyone is so caring ",
		// 	name: "Richard Feynman",
	},
	{
		text: "I had a great experience all round. The staff were amazing, friendly and very professional. The whole procedure from booking to obtaining final results ran smoothly. ",
		// 	name: "Richard Feynman",
	},
	{
		text: "Thanks for an excellent service. ",
		// 	name: "Richard Feynman",
	},
	{
		text: "Dr Brennan makes complex gastroenterology seemingly easy. Thank you Dr Brennan!",
		// 	name: "Richard Feynman",
	},
	{
		text: "Please keep up the brilliant work",
		// 	name: "Richard Feynman",
	},
	{
		text: "All the staff were very helpful and made the whole experience as painless as possible. ",
		// 	name: "Richard Feynman",
	},
	{
		text: "Doctor Rao was extremely friendly and very professional ",
		// 	name: "Richard Feynman",
	},
	{
		text: "The easiest colonoscopy prep I've had",
		// 	name: "Richard Feynman",
	},
	{
		text: "your service is tops in my book",
		// 	name: "Richard Feynman",
	},
	{
		text: "Caring and professional ",
		// 	name: "Richard Feynman",
	},
	{
		text: "I would recommend this for all I know ",
		// 	name: "Richard Feynman",
	},
	{
		text: "Staff were all very helpful and friendly",
		// 	name: "Richard Feynman",
	},
	{
		text: "I was very happy with my visit ",
		// 	name: "Richard Feynman",
	},
	{
		text: "Reception staff very welcoming and friendly.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Everyone was so lovely. I felt in good care",
		// 	name: "Richard Feynman",
	},
	{
		text: "Very efficient and comprehensive with great patient feed back and comfort. At no stage did I feel lost in the system. All people were kind and helpful, especially the after treatment nurse who let me recover a little longer.",
		// 	name: "Richard Feynman",
	},
	{
		text: "The colonoscopy at Peel H.Campus was exemplary.  Felt very cared for.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Best staff I’ve had look after me.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Dr. Raftopoulos was very professional and explained everything clearly.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Keep up the excellent work and keep smiling!!!",
		// 	name: "Richard Feynman",
	},
	{
		text: "Current fantastic nursing staff make you feel your care is really important to them personally",
		// 	name: "Richard Feynman",
	},
	{
		text: "The staff were very friendly and made me feel less anxious ",
		// 	name: "Richard Feynman",
	},
	{
		text: "Thankyou for service and reassurance",
		// 	name: "Richard Feynman",
	},
	{
		text: "Obviously dedicated to making patients feel safe, comfortable & well informed.",
		// 	name: "Richard Feynman",
	},
	{
		text: "Excellent service ",
		// 	name: "Richard Feynman",
	},
	{
		text: "Very happy with the process. Thank you very much ",
		// 	name: "Richard Feynman",
	},
	{
		text: "Thanks for the excellent care Dr Raftopoulos",
		// 	name: "Richard Feynman",
	},
	{
		text: "Since my first visit to Dr Spiro, everything has gone very smoothly and professionally. I have appreciated his advice and professional assistance. He has got me to where I no longer require his assistance - hopefully!!",
		// 	name: "Richard Feynman",
	},
	// {
	// 	text:
	// 		"Any moment might be our last. Everything is more beautiful because we're doomed. You will never be lovelier than you are now. We will never be here again.",
	// 	name: "Homer",
	// },
	// {
	// 	text: "It always seems impossible, until it is done.",
	// 	name: "Nelson Mandela",
	// },
	// {
	// 	text:
	// 		"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed urna nulla vitae laoreet augue. Amet feugiat est integer dolor auctor adipiscing nunc urna, sit.",
	// },
];
